import { createSelector } from 'reselect';
import { selectCurrentUserId } from './auth';
import { AppState } from '../stores/store';

export const selectEnvironment = (state: AppState) => state.config.environment;

export const selectAppName = (state: AppState) => state.config.appName;

export const selectAppTitle = (state: AppState) => state.config.appTitle;

export const selectAppUrl = (state: AppState) => state.config.appUrl;

export const selectApiUrl = (state: AppState) => state.config.apiUrl;

export const selectLmsUrl = (state: AppState) => state.config.lmsUrl;

export const selectPanelUrl = (state: AppState) => state.config.panelUrl;

export const selectEmdUrl = (state: AppState) => state.config.emdUrl;

export const selectBulkMessagingUrl = (state: AppState) => state.config.bmUrl;

export const selectReminderUrl = (state: AppState) => state.config.reminderUrl;

export const selectMhrUrl = (state: AppState) => state.config.mhrUrl;

export const selectCompanyName = (state: AppState) => state.config.companyName;

export const selectSocketUrlRaw = (state: AppState) => state.config.socketUrl;

export const selectSupportUrl = (state: AppState) => state.config.supportUrl;

export const selectPatientSupportUrl = (state: AppState) =>
  state.config.patientSupportUrl;

export const selectIsProductionEnvironment = (state: AppState) =>
  state.config.environment === 'production';

export const selectIsNonProductionEnvironment = (state: AppState) =>
  state.config.environment !== 'production';

export const selectIsNonLocalEnvironment = (state: AppState) =>
  state.config.environment !== 'local';

export const selectSocketUrl = createSelector(
  selectSocketUrlRaw,
  selectCurrentUserId,
  (socketUrlRaw, currentUserId) => {
    if (currentUserId) {
      return `${socketUrlRaw}?id=${currentUserId}`;
    }
    return null;
  },
);

export const selectStripeKey = (state: AppState) => state.config.stripeKey;
