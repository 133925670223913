const config = () => {
  let appName = 'Secure-Mail';
  let baseUrl = 'dev-health.brightsquid.com';
  let basePanelUrl = 'dev-panel.brightsquid.com';
  let baseEmdUrl = 'dev-emd.brightsquid.com';
  let baseBmUrl = 'dev-message.brightsquid.com';
  let reminderUrl = 'brightsquid.com/pages/appointment-reminders-request-page';
  let baseLmsUrl;
  let lmsProtocol = 'https';
  let supportUrl = 'https://support.brightsquid.com';
  let patientSupportUrl = 'https://health.brightsquid.com/patientsupport';
  let companyName = 'Brightsquid';
  let stripeKey = 'pk_test_9SDIIPYmQmPQ3k63toBcWvXK';
  let mhrUrl = 'https://abphr.stg.telushealthspace.com/messagingLaunch.aspx';

  const environment = process.env.REACT_APP_ENVIRONMENT;

  if (environment === 'production') {
    baseUrl = 'health.brightsquid.com';
    //need to change basePanelUrl for production here
    basePanelUrl = 'panel.brightsquid.com';
    baseEmdUrl = 'emd.brightsquid.com';
    baseBmUrl = 'message.brightsquid.com';
    baseLmsUrl = baseUrl;
    stripeKey = 'pk_live_oii6rYSNvVD0SryIMzvCjn8q';
    mhrUrl = 'https://myhealthrecords.alberta.ca/messagingLaunch.aspx';
  } else if (environment === 'local') {
    baseUrl = 'localhost:8443';
    //need to change basePanelUrl for local here
    baseLmsUrl = 'localhost:8000';
    lmsProtocol = 'http';
  } else {
    baseUrl = `${environment}-health.brightsquid.com`;
    baseLmsUrl = baseUrl;
    basePanelUrl = `${environment}-panel.brightsquid.com`;
    baseEmdUrl = `${environment}-emd.brightsquid.com`;
    baseBmUrl = `${environment}-message.brightsquid.com`;
  }
  // need to add info for paperform will talk to francis about it latter
  return {
    appName,
    appUrl: `https://${baseUrl}/`,
    apiUrl: `https://${baseUrl}/api/v1/`,
    lmsUrl: `${lmsProtocol}://${baseLmsUrl}/lms/`,
    panelUrl: `https://${basePanelUrl}/`,
    emdUrl: `https://${baseEmdUrl}/`,
    bmUrl: `https://${baseBmUrl}/`,
    reminderUrl: `https://${reminderUrl}/`,
    supportUrl,
    patientSupportUrl,
    appTitle: `${companyName} ${appName}`,
    socketUrl: `wss://${baseUrl}/ws/handler`,
    companyName,
    stripeKey,
    mhrUrl,
    environment,
  };
};

export default config;
